<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div
              class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
            >
              <img
                src="@/assets/images/pages/reset-password.png"
                alt="login"
                class="mx-auto"
              />
            </div>
            <div
              class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg"
            >
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Reset password</h4>
                  <p>Please enter your new password.</p>
                </div>
                <vs-input
                  label-placeholder="Code"
                  v-model="code"
                  class="w-full mb-6"
                />
                <vs-input
                  type="email"
                  disabled
                  label-placeholder="Email"
                  v-model="email"
                  class="w-full mb-6"
                />
                <vs-input
                  icon-pack="feather"
                  label-placeholder="Password"
                  @icon-click="showPassword()"
                  icon-after="true"
                  :type="passwordType"
                  icon="icon icon-eye-off"
                  v-model="password"
                  class="w-full mb-6"
                  v-validate="'required|min:8'"
                  @input="checkpassword"
                />
                <vs-input
                  @icon-click="showPassword()"
                  label-placeholder="Confirm Password"
                  icon-after="true"
                  :type="passwordType"
                  icon="icon icon-eye-off"
                  icon-pack="feather"
                  v-model="password2"
                  class="w-full mb-8"
                />
                <ul class="ml-2 mt-3">
                  <li v-bind:class="{ is_valid: contains_eight_characters }">
                    8 Characters
                  </li>
                  <li v-bind:class="{ is_valid: contains_number }">
                    Contains Number
                  </li>
                  <li v-bind:class="{ is_valid: contains_uppercase }">
                    Contains Uppercase
                  </li>
                  <li v-bind:class="{ is_valid: contains_special_character }">
                    Contains Special Character
                  </li>
                </ul>
                <div
                  class="flex flex-wrap justify-between flex-col-reverse sm:flex-row"
                >
                  <vs-button
                    type="border"
                    to="/pages/login"
                    class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
                    >Go Back To Login</vs-button
                  >
                  <vs-button
                    class="w-full sm:w-auto"
                    :disabled="
                      !(
                        code &&
                        password == password2 &&
                        this.contains_eight_characters &&
                        this.contains_number &&
                        this.contains_uppercase &&
                        this.contains_special_character
                      )
                    "
                    @click="Resetpassword"
                    >Reset</vs-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import moduleUser from "@/store/user/moduleUser.js";
export default {
  data() {
    return {
      passwordType: "password",
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      email: this.$route.params.email,
      password: "",
      code: null,
      password2: null
    };
  },
  methods: {
    showPassword() {
      if (this.passwordType == "text") this.passwordType = "password";
      else this.passwordType = "text";
    },
 Resetpassword() {
      debugger;
      var data = {
        code: this.code,
        email: this.email,
        password: this.password
      };
      this.$store
        .dispatch("UserList/ResetPassword", data)
        .then(res => {
          this.$vs.notify({
            color: "sucess",
            title: "Reset Password",
            text: res.data.Message
          });
          this.$router.push("/pages/login");
          // if (res.data == "reset done successfully") {
          //   this.$router.push("/pages/login");
          // }
        })
        .catch(err => {
           this.$vs.notify({
            color: "danger",
            title: "Reset Password",
            text: "your Code is not correct"
          });
        });
    },
    checkpassword() {
      this.password_length = this.password.length;
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
      this.contains_special_character = format.test(this.password);

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    }
  },
  created() {
    if (!moduleUser.isRegistered) {
      this.$store.registerModule("UserList", moduleUser);
      moduleUser.isRegistered = true;
    }
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style>
.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 50%;
}
li {
  margin-bottom: 8px;
  color: #525f7f;
  position: relative;
}

li:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
</style>
